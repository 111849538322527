import * as React from 'react';
import { startTheGame, startTheGameFullScreen, startTheGameDynamic } from '../mainfile';
import { GameState } from '../redux/state';
import { connect } from 'react-redux';
import { setGameVarOnCanvasMountActionCreator } from '../redux/actions';
import { BOTTOM_MARGIN, RIGHT_MARGIN } from '../ScreenUtils';

export class MarginsGameContainerNode extends React.Component {
    componentDidMount() {
        // startTheGame();
        startTheGameFullScreen();
    }

    render(): React.ReactNode {
        // return (
        //     <div id="phaserhook"></div>
        // );

        return (
            <div style={{margin: '25px'}}>
                <div id="phaserhook"></div>
            </div>
        );

        // return (
        //     <div style={{padding: '25px'}}>
        //         <div id="phaserhook"></div>
        //     </div>
        // );
    }
}

export class FullScreenGameContainerNode extends React.Component {
    componentDidMount() {
        // startTheGame();
        startTheGameFullScreen();
    }

    render(): React.ReactNode {
        return (
            <div id="phaserhook"></div>
        );
    }
}

export class GameContainerNode extends React.Component {
    componentDidMount() {
        startTheGame();
        // startTheGameFullScreen();
    }

    render(): React.ReactNode {
        return (
            <div id="phaserhook"></div>
        );
    }
}



interface DispatchProps {
    dispatch: any
}

// interface DynamicNodeProps {
//     myHeight: number;
//     myWidth: number;
// }
function mapStateToProps(state: GameState): GameState {
    return {
        ...state
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        dispatch
    };
}
//export class DynamicGameNode extends React.Component {
class DynamicGameNode extends React.Component<GameState & DispatchProps> {
    componentDidMount() {
        console.log("CALLED THE FOLLOWING: componentDidMount() from DynamicGameNode");

        // this.props.
        //startTheGameDynamic(this.props.windowHeight - 20, this.props.windowWidth - 20);
        let gameWrapper = startTheGameDynamic(this.props.windowHeight - BOTTOM_MARGIN, this.props.windowWidth - RIGHT_MARGIN);
        let phaserGameVar: Phaser.Game = gameWrapper.game;
        //startTheGameDynamic(100, 100); //height, width
        // startTheGameFullScreen();

        //TODO: fire off an action, where phaserGameVar is set on the global state...
        //THEN: call the function below off of resize actionCreator events...
        // phaserGameVar.scale.resize()

        //phaserGameVar.scale.refresh()
        this.props.dispatch(setGameVarOnCanvasMountActionCreator(phaserGameVar));
        // setGameVarOnCnvasMountActionCreator
    }

    render(): React.ReactNode {
        return (
            <div id="phaserhook"></div>
        );
    }
}
// let DynamicGameContainer = connect(mapStateToProps, mapDispatchToProps)(DynamicGameNode);
// export DynamicGameContainer;
export const DynamicGameContainer = connect(mapStateToProps, mapDispatchToProps)(DynamicGameNode);
// export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);


// export default GameContainerNode;