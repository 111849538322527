import { MapBlob } from "../levelBlobs";
import { GameScreens, LevelOptions } from "../ScreenUtils";




// export interface ActionCreator<T> {
//     type: PossibleActions;
//     payload: T;
// }

export interface GameAction<T> {
    type: PossibleGameActions;
    payload: T;
}



export enum PossibleGameActions {
    SET_SCREEN = 'SET_SCREEN',
    SET_LEVEL = 'SET_LEVEL',
    SET_PLAY_MODE = 'SET_PLAY_MODE',
    SCREEN_RESIZE = 'SCREEN_RESIZE',
    SET_GAME_VAR_ON_CANVAS_MOUNT = 'SET_GAME_VAR_ON_CANVAS_MOUNT',
    NULL_ACTION = 'NULL_ACTION',
}

// export const SET_SCREEN = 'SET_SCREEN';


// export const setScreenActionCreator = (screen: GameScreens) => {
//     type: PossibleActions.SET_SCREEN,
//     payload: screen
// }
export function setScreenActionCreator(screen: GameScreens): GameAction<GameScreens> {
    return {
        type: PossibleGameActions.SET_SCREEN,
        payload: screen
    }
}

// interface TileClickPayload {
//     row: number;
//     col: number;
// }
// function tileClickActionCreator(tile: TileClickPayload): CanvasAction<TileClickPayload> {
//     return {
//         type: PossibleCanvasActions.TILE_CLICK_EVENT,
//         payload: tile
//     }
// }

export interface SetLevelPayload {
    levelOption: LevelOptions;
    levelMapBlob: MapBlob;
}
export function setLevelActionCreator(level: SetLevelPayload): GameAction<SetLevelPayload> {
    return {
        type: PossibleGameActions.SET_LEVEL,
        payload: level
    }
}

// export function setLevelActionCreator(level: LevelOptions): GameAction<LevelOptions> {
//     return {
//         type: PossibleGameActions.SET_LEVEL,
//         payload: level
//     }
// }

export function setPlayModeActionCreator(playMode: boolean): GameAction<boolean> {
    return {
        type: PossibleGameActions.SET_PLAY_MODE,
        payload: playMode
    }
}

export interface ScreenDimensions {
    width: number;
    height: number;
}

export function screenResizeActionCreator(screenSize: ScreenDimensions): GameAction<ScreenDimensions> {
    return {
        type: PossibleGameActions.SCREEN_RESIZE,
        payload: screenSize
    }
}

export function setGameVarOnCanvasMountActionCreator(gameVar: Phaser.Game): GameAction<Phaser.Game> {
    return {
        type: PossibleGameActions.SET_GAME_VAR_ON_CANVAS_MOUNT,
        payload: gameVar
    }
}

export function nullActionCreator(): GameAction<any> {
    return {
        type: PossibleGameActions.NULL_ACTION,
        payload: null
    }
}
