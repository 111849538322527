import * as React from 'react';
// import * as Redux from 'redux';
//import { startGame, startTheGame } from './mainfile';
// import { startTheGame } from './mainfile';
import {
    MarginsGameContainerNode,
    FullScreenGameContainerNode,
    GameContainerNode,
    // DynamicGameNode,
    DynamicGameContainer
} from './components/GameNode';
import { GameScreens, LevelOptions } from './ScreenUtils';

import { connect } from 'react-redux';
import { GameState } from './redux/state';

import {
    setLevelActionCreator,
    setScreenActionCreator,
    setPlayModeActionCreator,
    ScreenDimensions,
    screenResizeActionCreator,
} from './redux/actions';
import { MapBlob, LEVEL_ONE_MAP, LEVEL_TWO_MAP, DEFAULT_MAP } from './levelBlobs';



// interface MyProps {
//     //
// }

interface MyState {
    // componentCurrentScreen: GameScreens;
    // showMargins: boolean;
    // showFullScreen: boolean;
    // showGame: boolean;
}

// window resize
// https://phaser.io/examples/v3/view/scalemanager/resize
// https://stackoverflow.com/questions/48964723/phaser-3-change-dimensions-of-the-game-during-runtime
// https://www.html5gamedevs.com/topic/13177-canvas-resize-how-to-always-make-entire-game-visible/







// interface StateProps {
//     //
// }

// function mapStateToProps(state: GameState): StateProps {
//     return {
//         currentScreen: state.currentScreen
//     }
// }



// interface DispatchProps {
//     dispatch: (action: any) => void
// }

interface DispatchProps {
    dispatch: any
}



function mapStateToProps(state: GameState): GameState {
    return {
        ...state
    }
}

// function mapDispatchToProps(dispatch: Redux.Dispatch<any>) {
//     return {
//         dispatch
//     };
// }

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch
    };
}

// class MainComponent extends React.Component<MyProps & GameState, MyState> {
class MainComponent extends React.Component<GameState & DispatchProps, MyState> {
    constructor(props: any) {
        super(props);

        this.handleMarginsClick = this.handleMarginsClick.bind(this);
        this.handleFullScreenClick = this.handleFullScreenClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickDynamic = this.handleClickDynamic.bind(this);

        this.clickLevelAndPlayButton = this.clickLevelAndPlayButton.bind(this);

        this.clickPlayButton = this.clickPlayButton.bind(this);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }



    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        // TODO: dispatch actionCreator for resizing
        console.log("RESIZE EVENT");
        let screenSize: ScreenDimensions = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
        this.props.dispatch(screenResizeActionCreator(screenSize));
    }




    handleMarginsClick() {
        this.props.dispatch(setScreenActionCreator(GameScreens.MARGIN_SCREEN));
    }

    handleFullScreenClick() {
        this.props.dispatch(setScreenActionCreator(GameScreens.FULL_SCREEN));
    }

    handleClick() {
        this.props.dispatch(setScreenActionCreator(GameScreens.FIXED_SCREEN));
    }

    handleClickDynamic() {
        this.props.dispatch(setScreenActionCreator(GameScreens.DYNAMIC_SCREEN));
    }




    // clickLevelButton(levelSelected: LevelOptions) {
    //     this.props.dispatch(setLevelActionCreator(levelSelected));
    // }

    clickLevelAndPlayButton(levelSelected: LevelOptions, mapBlob: MapBlob) {
        // this.props.dispatch(setLevelActionCreator(levelSelected));
        this.props.dispatch(setLevelActionCreator({
            levelOption: levelSelected,
            levelMapBlob: mapBlob,
        }));

        this.props.dispatch(setScreenActionCreator(GameScreens.DYNAMIC_SCREEN));

        this.props.dispatch(setPlayModeActionCreator(true));
    }

    clickPlayButton() {
        this.props.dispatch(setPlayModeActionCreator(true));
    }

    renderLevelButton(levelOption: LevelOptions, levelString: string, mapBlob: MapBlob) {
        return (
            <button
                className={'w3-button w3-red w3-round-xlarge'}
                onClick={() => this.clickLevelAndPlayButton(levelOption, mapBlob)}>
                    {levelString}
            </button>
        );
        // return (
        //     <button
        //         className={`w3-button ${(this.props.levelSelection === levelOption) ? 'w3-green' : 'w3-red'} w3-round-xlarge w3-center`}
        //         onClick={() => this.clickLevelButton(levelOption)}>
        //             {levelString}
        //     </button>
        // );
    }

    render(): React.ReactNode {
        // window.onload = startGame;

        if (this.props.playMode && this.props.currentScreen === GameScreens.MARGIN_SCREEN) {
            return (
                <MarginsGameContainerNode />
            );
        }

        if (this.props.playMode && this.props.currentScreen === GameScreens.FULL_SCREEN) {
            return (
                <FullScreenGameContainerNode />
            );
        }

        if (this.props.playMode && this.props.currentScreen === GameScreens.FIXED_SCREEN) {
            return (
                <GameContainerNode />
            );
        }

        if (this.props.playMode && this.props.currentScreen === GameScreens.DYNAMIC_SCREEN) {
            // return (
            //     <DynamicGameNode />
            // );
            return (
                <DynamicGameContainer />
            );
        }

        const styleVar: React.CSSProperties = {
            backgroundImage: 'url("http://thecanvasgame.com/assets/sky.png")',
            backgroundSize: 'cover',
            minHeight: '100%',
            height: '100%',
            position: 'relative'
        }

        return (
            <div style={styleVar}>
                <div className="w3-row">
                    <div className="w3-col s6 w3-center">
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_ONE, "LEVEL ONE", LEVEL_ONE_MAP)}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_TWO, "LEVEL TWO", LEVEL_TWO_MAP)}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_THREE, "LEVEL THREE", DEFAULT_MAP)}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_FOUR, "LEVEL FOUR", DEFAULT_MAP)}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_FIVE, "LEVEL FIVE", DEFAULT_MAP)}
                    </div>
                    <div className="w3-col s6 w3-center">
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_SIX, "LEVEL SIX", DEFAULT_MAP)}
                        {/* <button
                            className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_ONE) ? 'w3-green' : 'w3-red'} w3-round-xlarge w3-center`}
                            onClick={() => this.clickLevelButton(LevelOptions.LEVEL_ONE)}>
                                LEVEL ONE
                        </button> */}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_SEVEN, "LEVEL SEVEN", DEFAULT_MAP)}
                        {/* <button
                            className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_ONE) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
                            onClick={() => this.clickLevelButton(LevelOptions.LEVEL_ONE)}>
                                LEVEL ONE
                        </button> */}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_EIGHT, "LEVEL EIGHT", DEFAULT_MAP)}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_NINE, "LEVEL NINE", DEFAULT_MAP)}
                        <br/><br/>
                        {this.renderLevelButton(LevelOptions.LEVEL_TEN, "LEVEL TEN", DEFAULT_MAP)}
                    </div>
                </div>
            </div>
        );

        // return (
        //     <div style={styleVar}>
        //         <div className="w3-row">
        //             <div className="w3-col s4 w3-center">
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.currentScreen === GameScreens.MARGIN_SCREEN) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
        //                     onClick={this.handleMarginsClick}>
        //                         MARGINS
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.currentScreen === GameScreens.FULL_SCREEN) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
        //                     onClick={this.handleFullScreenClick}>
        //                         FULL SCREEN
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.currentScreen === GameScreens.FIXED_SCREEN) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
        //                     onClick={this.handleClick}>
        //                         FIXED SCREEN
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.currentScreen === GameScreens.DYNAMIC_SCREEN) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
        //                     onClick={this.handleClickDynamic}>
        //                         DYNAMIC SCREEN
        //                 </button>
        //             </div>

        //             <div className="w3-col s4">
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_ONE) ? 'w3-green' : 'w3-red'} w3-round-xlarge w3-center`}
        //                     onClick={() => this.clickLevelButton(LevelOptions.LEVEL_ONE)}>
        //                         LEVEL ONE
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_TWO) ? 'w3-green' : 'w3-red'} w3-round-xlarge w3-center`}
        //                     onClick={() => this.clickLevelButton(LevelOptions.LEVEL_TWO)}>
        //                         LEVEL TWO
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_THREE) ? 'w3-green' : 'w3-red'} w3-round-xlarge w3-center`}
        //                     onClick={() => this.clickLevelButton(LevelOptions.LEVEL_THREE)}>
        //                         LEVEL THREE
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_FOUR) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
        //                     onClick={() => this.clickLevelButton(LevelOptions.LEVEL_FOUR)}>
        //                         LEVEL FOUR
        //                 </button>
        //                 <br/><br/>
        //                 <button
        //                     className={`w3-button ${(this.props.levelSelection === LevelOptions.LEVEL_FIVE) ? 'w3-green' : 'w3-red'} w3-round-xlarge`}
        //                     onClick={() => this.clickLevelButton(LevelOptions.LEVEL_FIVE)}>
        //                         LEVEL FIVE
        //                 </button>
        //             </div>

        //             <div className="w3-col s4">
        //                 <button
        //                     className={`w3-button w3-red w3-round-xlarge`}
        //                     onClick={this.clickPlayButton}>
        //                         PLAY
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        // );

        // return (
        //     <div id="phaserhook"></div>
        // );
    }
}

//export default MainComponent;
export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);