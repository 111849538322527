// export const BOTTOM_MARGIN: number = 20;
// export const RIGHT_MARGIN: number = 20;
export const BOTTOM_MARGIN: number = 3;
export const RIGHT_MARGIN: number = 3;

export enum GameScreens {
    HOME_SCREEN = 'HOME_SCREEN',
    LEVELS = 'LEVELS',
    GAME_PLAY = 'GAME_PLAY',

    MARGIN_SCREEN = 'MARGIN_SCREEN',
    FULL_SCREEN = 'FULL_SCREEN',
    FIXED_SCREEN = 'FIXED_SCREEN',
    DYNAMIC_SCREEN = 'DYNAMIC_SCREEN',
}

export enum LevelOptions {
    LEVEL_ONE = 'LEVEL_ONE',
    LEVEL_TWO = 'LEVEL_TWO',
    LEVEL_THREE = 'LEVEL_THREE',
    LEVEL_FOUR = 'LEVEL_FOUR',
    LEVEL_FIVE = 'LEVEL_FIVE',
    LEVEL_SIX = 'LEVEL_SIX',
    LEVEL_SEVEN = 'LEVEL_SEVEN',
    LEVEL_EIGHT = 'LEVEL_EIGHT',
    LEVEL_NINE = 'LEVEL_NINE',
    LEVEL_TEN = 'LEVEL_TEN',
}