import { GameScreens, LevelOptions } from '../ScreenUtils';
import { MapBlob } from '../levelBlobs';

export type GameState = {
    currentScreen: GameScreens;
    levelSelection: LevelOptions;
    levelMapBlob?: MapBlob;
    playMode: boolean;
    windowWidth: number;
    windowHeight: number;
    phaserGameObj?: Phaser.Game;
}
export const initialGameState: GameState = {
    currentScreen: GameScreens.HOME_SCREEN,
    levelSelection: LevelOptions.LEVEL_ONE,
    // levelMapBlob: undefined,
    playMode: false,
    windowWidth: 0,
    windowHeight: 0,
    phaserGameObj: undefined,
}