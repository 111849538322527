//import { GameScreens, LevelOptions } from '../../ScreenUtils';
import { GameScreens } from '../../ScreenUtils';
import { GameAction, PossibleGameActions, ScreenDimensions, SetLevelPayload } from '../actions';
import { GameState, initialGameState } from '../state';

export function rootReducer(state: GameState = initialGameState, action: GameAction<any>): GameState {
    // console.log(action.type);
    // console.log(action);

    switch(action.type) {
        case PossibleGameActions.SET_SCREEN:
            let currAction: GameAction<GameScreens> = action;
            return {
                ...state,
                currentScreen: currAction.payload,
            };
        case PossibleGameActions.SET_LEVEL:
            //SetLevelPayload
            //let setLevelAction: GameAction<LevelOptions> = action;
            let setLevelAction: GameAction<SetLevelPayload> = action;
            return {
                ...state,
                levelSelection: setLevelAction.payload.levelOption,
                levelMapBlob: setLevelAction.payload.levelMapBlob,
            };
        case PossibleGameActions.SET_PLAY_MODE:
            let setPlayModeAction: GameAction<boolean> = action;
            // console.log("BBBBBBBBBBBBBBBBBBBBB");
            return {
                ...state,
                playMode: setPlayModeAction.payload,
            };
        case PossibleGameActions.SCREEN_RESIZE:
            let screenResizeAction: GameAction<ScreenDimensions> = action;

            //phaserGameVar.scale.resize()
            // state.phaserGameObj

            // if (!!state.phaserGameObj) {
            //     state.phaserGameObj?.scale.resize(screenResizeAction.payload.width - 20, screenResizeAction.payload.height - 20);
            // }

            return {
                ...state,
                windowWidth: screenResizeAction.payload.width,
                windowHeight: screenResizeAction.payload.height,
            };
        case PossibleGameActions.SET_GAME_VAR_ON_CANVAS_MOUNT:
            let setGameVarAction: GameAction<Phaser.Game> = action;
            return {
                ...state,
                phaserGameObj: setGameVarAction.payload,
            };
        default:
            return {
                ...state,
            }
    }
}