import { createStore, Store, applyMiddleware } from "redux";
import { rootReducer } from './reducers/root';
import { GameState } from "./state";
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { resizeScreenEpic } from "./epics";


export const rootEpic = combineEpics(
    resizeScreenEpic
);



const epicMiddleware = createEpicMiddleware();
// const epicMiddleware = createEpicMiddleware(rootEpic);



//export const gameStore: Store<GameState> = createStore(rootReducer);
export const gameStore: Store<GameState> = createStore(
    rootReducer,
    applyMiddleware(epicMiddleware)
);


epicMiddleware.run(rootEpic);


