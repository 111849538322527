import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import MainComponent from './MainApp';
import { Provider } from 'react-redux';
import { gameStore } from './redux/store';

ReactDOM.render(
    <React.StrictMode>
      <Provider store={gameStore}>
        <MainComponent />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
