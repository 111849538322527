import * as Phaser from 'phaser';
import { createStore, Store } from "redux";
import { GameState } from './redux/state';
import { gameStore } from './redux/store';
import { GameScreens, LevelOptions } from './ScreenUtils';
import {
    setScreenActionCreator,
    setPlayModeActionCreator,
} from './redux/actions';

import { Block, BlockType, RCPair } from './levelBlobs';





interface CanvasAction<T> {
    type: PossibleCanvasActions;
    payload: T;
}
enum PossibleCanvasActions {
    TILE_CLICK_EVENT = 'TILE_CLICK_EVENT',

    POINTER_UP_EVENT = 'POINTER_UP_EVENT',
    POINTER_DOWN_EVENT = 'POINTER_DOWN_EVENT',
    POINTER_MOVE_EVENT = 'POINTER_MOVE_EVENT',
}

interface TileClickPayload {
    row: number;
    col: number;
}
function tileClickActionCreator(tile: TileClickPayload): CanvasAction<TileClickPayload> {
    return {
        type: PossibleCanvasActions.TILE_CLICK_EVENT,
        payload: tile
    }
}
function pointerUpActionCreator(pair: XYPair): CanvasAction<XYPair> {
    return {
        type: PossibleCanvasActions.POINTER_UP_EVENT,
        payload: pair
    }
}
function pointerDownActionCreator(pair: XYPair): CanvasAction<XYPair> {
    return {
        type: PossibleCanvasActions.POINTER_DOWN_EVENT,
        payload: pair
    }
}
function pointerMoveActionCreator(pair: XYPair): CanvasAction<XYPair> {
    return {
        type: PossibleCanvasActions.POINTER_MOVE_EVENT,
        payload: pair
    }
}

//------------------------


type XYPair = {
    x: number;
    y: number;
}
// type RCPair = {
//     r: number;
//     c: number;
// }

type DragState = {
    offset: XYPair;
    tempOffset: XYPair;
    pointerCurrentlyDown: boolean; // aka currentlyDragging / isDragging
    maxDragDistance: number;
    dragThresholdExceeded: boolean;
    clickDownPair: XYPair;
    clickUpPair: XYPair;
    latestDragPair: XYPair;
}
const initialDragState: DragState = {
    offset: {
        x: 200,
        y: 200,
    },
    // offset: {
    //     x: 0,
    //     y: 0,
    // },
    tempOffset: {
        x: 0,
        y: 0,
    },
    pointerCurrentlyDown: false,
    maxDragDistance: 0,
    dragThresholdExceeded: false,
    clickDownPair: {
        x: 0,
        y: 0,
    },
    clickUpPair: {
        x: 0,
        y: 0,
    },
    latestDragPair: {
        x: 0,
        y: 0,
    },
}
type GameLogicState = {
    selectedTile?: RCPair;
}
const initialGameLogicState: GameLogicState = {
    selectedTile: undefined,
}
type CanvasState = {
    // levelTextX: number;
    dragState: DragState;
    gameLogicState: GameLogicState;
}
const initialCanvasState: CanvasState = {
    // levelTextX: 50,
    dragState: initialDragState,
    gameLogicState: initialGameLogicState,
}

function dragReducer(state: DragState = initialDragState, action: any): DragState {
    let xyPair: XYPair;
    switch(action.type) {
        case PossibleCanvasActions.POINTER_UP_EVENT:
            console.log(action);
            console.log("POINTER UP EVENT");
            xyPair = action.payload;


            //TODO: is maxDragDistance exceeded 16, then update the offset
            //if the maxDistance was exceeded, then commit the tempOffset to the regular offset
            let newOffsetX: number = state.offset.x;
            let newOffsetY: number = state.offset.y;
            if (state.maxDragDistance*state.maxDragDistance > 16*16) {
                newOffsetX += state.tempOffset.x;
                newOffsetY += state.tempOffset.y;
            }


            return {
                ...state,
                pointerCurrentlyDown: false,
                clickUpPair: {
                    x: xyPair.x,
                    y: xyPair.y,
                },
                offset: {
                    x: newOffsetX,
                    y: newOffsetY,
                },
                tempOffset: {
                    x: 0,
                    y: 0,
                },
            };
        case PossibleCanvasActions.POINTER_DOWN_EVENT:
            console.log(action);
            console.log("POINTER DOWN EVENT");
            xyPair = action.payload;
            return {
                ...state,
                pointerCurrentlyDown: true,
                maxDragDistance: 0,
                dragThresholdExceeded: false,
                clickDownPair: {
                    x: xyPair.x,
                    y: xyPair.y,
                },
            };
        case PossibleCanvasActions.POINTER_MOVE_EVENT:
            // console.log("DRAG EVENT");
            xyPair = action.payload;
            if (state.pointerCurrentlyDown) {
                let latestDragPair: XYPair = {
                    x: xyPair.x,
                    y: xyPair.y,
                }

                //TODO: the following might need flipped...
                let distanceX: number = latestDragPair.x - state.clickDownPair.x;
                let distanceY: number = latestDragPair.y - state.clickDownPair.y;

                //maxDragDistance
                let newMaxDragDistance: number = state.maxDragDistance;
                let currDragDistance: number = distanceX * distanceX + distanceY * distanceY;
                if (currDragDistance > state.maxDragDistance) {
                    newMaxDragDistance = currDragDistance;
                }


                let newTempOffsetX: number = state.tempOffset.x;
                let newTempOffsetY: number = state.tempOffset.y;
                //TODO: is maxDragDistance exceeded 16, then update the offset -- X -> tempOffset is what I want...
                let newDragThresholdExceeded: boolean = false;
                if (newMaxDragDistance > 16*16) {
                    newTempOffsetX = distanceX;
                    newTempOffsetY = distanceY;
                    newDragThresholdExceeded = true;
                }

                //drag event
                return {
                    ...state,
                    latestDragPair: latestDragPair,
                    maxDragDistance: newMaxDragDistance,
                    dragThresholdExceeded: newDragThresholdExceeded,
                    tempOffset: {
                        x: newTempOffsetX,
                        y: newTempOffsetY,
                    },
                };
            } else {
                return {
                    ...state,
                };
            }
        default:
            return {
                ...state,
            };
    }
}

function tileClickReducer(state: GameLogicState = initialGameLogicState, action: any): GameLogicState {
    let tileClickPayload: TileClickPayload = action.payload;

    let newSelectedTile: RCPair | undefined = {
        r: tileClickPayload.row,
        c: tileClickPayload.col,
    }

    if (state.selectedTile?.r === tileClickPayload.row && state.selectedTile?.c === tileClickPayload.col) {
        //then the currently selected tile has been clicked again
        //So, it should be deselected...
        newSelectedTile = undefined;
    }

    return {
        ...state,
        selectedTile: newSelectedTile,
    };
}
function canvasReducer(state: CanvasState = initialCanvasState, action: any): CanvasState {
    // console.log(action);
    switch(action.type) {
        case PossibleCanvasActions.TILE_CLICK_EVENT:
            console.log(action);
            let tileClickPayload: TileClickPayload = action.payload;
            //console.log("TILE CLICK: " + tileClickPayload.row + );
            console.log(`TILE CLICK: ${tileClickPayload.row} ${tileClickPayload.col}`);
            // return {
            //     ...state,
            // };
            // return tileClickReducer(state, action);
            if (state.dragState.dragThresholdExceeded) {
                //drag event is occurring; do not do business logic.
                return {
                    ...state,
                };
            } else {
                return {
                    ...state,
                    gameLogicState: tileClickReducer(state.gameLogicState, action),
                };
            }
        default:
            return {
                ...state,
                dragState: dragReducer(state.dragState, action),
            };
    }
    // return state;
}

let canvasStore: Store<CanvasState> = createStore(canvasReducer);




//TODO: H E R E
interface TileObj {
    tileImg?: Phaser.GameObjects.Image;
    tileImgList?: Array<Phaser.GameObjects.Image>;
    // hexagon?: any;
    // lines?: Array<any>;
    lines?: Array<Phaser.GameObjects.Line>;

    blockType?: BlockType;
    height: number;
}

const SPACING_WIDTH: number = 65; // from column to column
const SPACING_HEIGHT: number = 52; // from row to row
const TILE_HEIGHT_SPACING: number = 22;
function RCToBottomLeft(row: number, column: number, offsetX: number, offsetY: number): XYPair {
    let drawY: number = row * SPACING_HEIGHT;
    let drawX: number = column * SPACING_WIDTH;
    if (row % 2 === 1) {
        drawX += (SPACING_WIDTH / 2.0);
    }

    drawX += offsetX;
    drawY += offsetY;

    return {
        x: drawX,
        y: drawY,
    }
}
/**
 * Height position is assumed to be 0 based
 */
function RCHToBottomLeft(row: number, column: number, heightOffsetPosition: number, offsetX: number, offsetY: number): XYPair {
    let drawY: number = row * SPACING_HEIGHT;
    let drawX: number = column * SPACING_WIDTH;
    if (row % 2 === 1) {
        drawX += (SPACING_WIDTH / 2.0);
    }

    //drawY += heightOffsetPosition * TILE_HEIGHT_SPACING;
    drawY -= heightOffsetPosition * TILE_HEIGHT_SPACING;

    drawX += offsetX;
    drawY += offsetY;

    return {
        x: drawX,
        y: drawY,
    }
}


const LETTERS_LAYER: number = 5;
const HUD_LAYER: number = 4;
const OUTLINE_LAYER: number = 3;
const POLYGON_LAYER: number = 2;
const BLOCK_LAYER: number = 1;
const BACKGROUND_LAYER: number = 0;


//export default class MyDemo extends Phaser.Scene {
export class MyDemo extends Phaser.Scene {
    constructor() {
        super('demo');

        // this.skyPic;
        // this.tileMap = new Map();
        this.tileObjMap = new Map();
    }
    // helloWorld: any;
    // staticText: any;
    levelText: any;
    // tileMap: Map<string, any>;
    tileObjMap: Map<string, TileObj>;
    mainMenuRect: any;

    //selectedTilePolygon: any;// : Phaser.GameObjects.Polygon
    selectedTilePolygon: Phaser.GameObjects.Polygon | undefined;

    //Phaser.GameObjects.Text
    //Phaser.GameObjects.Image
    // skyPic: any;
    // skyPic: Phaser.GameObjects.Image;

    preload() {
        // this.load.image('logo', 'assets/phaser3-logo.png');

        // this.load.setBaseURL('http://thecanvasgame.com');
        // this.load.image('sky', 'assets/sky.png');

        // this.load.image('grassTile', 'assets/tile_grass.png');

        //this.load.image('sky', 'http://thecanvasgame.com/assets/sky.png');
        this.load.image('sky', 'https://i.imgur.com/1BplutT.png');

        this.load.image('grassTile', 'https://i.imgur.com/caVjVRc.png');

        this.load.image('autumnTile', 'https://i.imgur.com/EX8fcHe.png');
        this.load.image('dirtTile', 'https://i.imgur.com/YVFHdZs.png');
        this.load.image('sandTile', 'https://i.imgur.com/4vxnV7A.png');
        this.load.image('waterTile', 'https://i.imgur.com/Dp4j4Wz.png');
    }

    create() {
        //console.log("CREATE() FUNCTION CALLED");
        console.log("CALLED THE FOLLOWING: create() from Phaser.Scene class");
        //CALLED THE FOLLOWING: new Phaser.Game(config)

        let mainStore: GameState = gameStore.getState();
        let levelSelected: LevelOptions = mainStore.levelSelection;





        // let skyPic = this.add.image(5, 5, 'sky');
        let skyPic: Phaser.GameObjects.Image = this.add.image(0, 0, 'sky');
        let minWidthScale = mainStore.windowWidth / 640.0;
        let minHeightScale = mainStore.windowHeight / 480.0;
        let minScale = (minWidthScale > minHeightScale) ? minWidthScale : minHeightScale;
        skyPic.setScale(minScale, minScale);
        skyPic.setOrigin(0.0, 0.0); // 0.5, 0.5 means that I'm setting location by the center of the image
        skyPic.setDepth(BACKGROUND_LAYER);


        // this.helloWorld = this.add.text(
        //     this.cameras.main.centerX,
        //     this.cameras.main.centerY,
        //     "hello", {
        //         font: "40px Arial",
        //         fill: "#ffffff"
        //     }
        // );


        let newText: Phaser.GameObjects.Text = this.add.text(
            20,
            20,
            levelSelected.toString(), {
                font: "20px Arial",
                fill: "#000000"
            }
        );
        this.levelText = newText;
        this.levelText.setOrigin(0.0, 0.0);
        this.levelText.setDepth(LETTERS_LAYER);



        let newRect: Phaser.GameObjects.Rectangle = this.add.rectangle(20, 55, 220, 40, 0xf44336);
        this.mainMenuRect = newRect;
        // this.mainMenuRect = this.add.rectangle(50, 100, 80, 20, 0xffaaaa);
        this.mainMenuRect.setOrigin(0.0, 0.0);
        this.mainMenuRect.setDepth(HUD_LAYER);
        this.mainMenuRect.setInteractive();
        this.mainMenuRect.on('pointerup', function() {
            // canvasStore.dispatch(mainMenuActionCreator());
            // mainStore.dispatch();

            // console.log("AAAAAAAAAAAAAAAAAAAAAAA");
            
            gameStore.dispatch(setScreenActionCreator(GameScreens.HOME_SCREEN));
            gameStore.dispatch(setPlayModeActionCreator(false));

            // this.props.dispatch(setScreenActionCreator(GameScreens.DYNAMIC_SCREEN));
            // this.props.dispatch(setPlayModeActionCreator(true));
        });

        this.add.text(
                30,
                65,
                "Return to Main Menu", {
                    font: "20px Arial",
                    fill: "#ffffff"
                }
            )
            .setOrigin(0.0, 0.0)
            .setDepth(LETTERS_LAYER)
            .setBackgroundColor('#f44336');
            // .setBackgroundColor('#ffffffff');



        const currDragState: DragState = canvasStore.getState().dragState;
        const offsetX: number = currDragState.offset.x + currDragState.tempOffset.x;
        const offsetY: number = currDragState.offset.y + currDragState.tempOffset.y;

        const bottom: XYPair = {
            x: 0 + 32.5,
            y: 0 + 68,
            // y: drawPair.y + 66,
        };
        const bottomLeft: XYPair = {
            x: 0,
            // y: drawPair.y + 51,
            y: 0 + 52,
        };
        const bottomRight: XYPair = {
            x: 0 + 65,
            // y: drawPair.y + 51,
            y: 0 + 52,
        };
        const top: XYPair = {
            x: 0 + 32.5,
            y: 0,
        };
        const topLeft: XYPair = {
            x: 0,
            y: 0 + 16,
        };
        const topRight: XYPair = {
            x: 0 + 65,
            y: 0 + 16,
        };



        let blockTypeMap: Map<string, BlockType> = new Map();
        let blockHeightMap: Map<string, number> = new Map();
        //do a first pass of the levelMapBlob list into a map of RC string -> BlockType
        mainStore.levelMapBlob?.blocks.forEach((block: Block) => {
            blockTypeMap.set(`${block.rcIndex.r}-${block.rcIndex.c}`, block.blockType);
            blockHeightMap.set(`${block.rcIndex.r}-${block.rcIndex.c}`, block.height);
        });



        // mainStore.levelMapBlob?.rowCount
        // R-C loop
        for (let r = 0; r < (mainStore.levelMapBlob?.rowCount || 1); r++) {
            for (let c = 0; c < (mainStore.levelMapBlob?.columnCount || 1); c++) {
                let currBlockHeight: number | undefined = blockHeightMap.get(`${r}-${c}`) || 1;
                let currBlockType: BlockType | undefined = blockTypeMap.get(`${r}-${c}`);

                let currTileList: Array<Phaser.GameObjects.Image> = [];

                for (let h = 0; h < (currBlockHeight); h++) {
                    let drawPair: XYPair = RCHToBottomLeft(r, c, h, offsetX, offsetY);

                    let currTile: Phaser.GameObjects.Image | undefined;
                    switch (currBlockType) {
                        case BlockType.GRASS:
                            currTile = this.add.image(drawPair.x, drawPair.y, 'grassTile');
                            break;
                        case BlockType.DIRT:
                            currTile = this.add.image(drawPair.x, drawPair.y, 'dirtTile');
                            break;
                        case BlockType.AUTUMN:
                            currTile = this.add.image(drawPair.x, drawPair.y, 'autumnTile');
                            break;
                        case BlockType.SAND:
                            currTile = this.add.image(drawPair.x, drawPair.y, 'sandTile');
                            break;
                        case BlockType.WATER:
                            currTile = this.add.image(drawPair.x, drawPair.y, 'waterTile');
                            break;
                        default: // NONE or undefined
                            currTile = undefined;
                            break;
                    }

                    currTile?.setOrigin(0.0, 0.0);
                    currTile?.setDepth(BLOCK_LAYER);
                    currTile?.setInteractive();
                    currTile?.on('pointerup', function() {
                        canvasStore.dispatch(tileClickActionCreator({
                            row: r,
                            col: c,
                        }));
                    });

                    if (!!currTile) {
                        currTileList.push(currTile);
                    }
                }




                /***********************/

                // let drawPair: XYPair = RCToBottomLeft(r, c, offsetX, offsetY);



                // //HERE?
                // //blockTypeMap
                // let currTile: Phaser.GameObjects.Image | undefined;
                // let currBlockType: BlockType | undefined = blockTypeMap.get(`${r}-${c}`);
                // switch (currBlockType) {
                //     case BlockType.GRASS:
                //         currTile = this.add.image(drawPair.x, drawPair.y, 'grassTile');
                //         break;
                //     case BlockType.DIRT:
                //         currTile = this.add.image(drawPair.x, drawPair.y, 'dirtTile');
                //         break;
                //     case BlockType.AUTUMN:
                //         currTile = this.add.image(drawPair.x, drawPair.y, 'autumnTile');
                //         break;
                //     case BlockType.SAND:
                //         currTile = this.add.image(drawPair.x, drawPair.y, 'sandTile');
                //         break;
                //     case BlockType.WATER:
                //         currTile = this.add.image(drawPair.x, drawPair.y, 'waterTile');
                //         break;
                //     default: // NONE or undefined
                //         currTile = undefined;
                //         break;
                // }


                // currTile?.setOrigin(0.0, 0.0);
                // currTile?.setDepth(BLOCK_LAYER);
                // currTile?.setInteractive();
                // currTile?.on('pointerup', function() {
                //     canvasStore.dispatch(tileClickActionCreator({
                //         row: r,
                //         col: c,
                //     }));
                // });

                /***********************/


                //here?


                let drawPair: XYPair = RCHToBottomLeft(r, c, (currBlockHeight), offsetX, offsetY);
                
                let lineObjects: Array<Phaser.GameObjects.Line> = [];
                if (!(!currBlockType || currBlockType === BlockType.NONE)) {
                    lineObjects = [
                        this.add.line(drawPair.x, drawPair.y, topLeft.x, topLeft.y, bottomLeft.x, bottomLeft.y, 0x000000)
                                .setOrigin(0.0, 0.0)
                                .setDepth(OUTLINE_LAYER),
                        this.add.line(drawPair.x, drawPair.y, top.x, top.y, topLeft.x, topLeft.y, 0x000000)
                                .setOrigin(0.0, 0.0)
                                .setDepth(OUTLINE_LAYER),
                        this.add.line(drawPair.x, drawPair.y, bottom.x, bottom.y, bottomLeft.x, bottomLeft.y, 0x000000)
                                .setOrigin(0.0, 0.0)
                                .setDepth(OUTLINE_LAYER),
                        this.add.line(drawPair.x, drawPair.y, topRight.x, topRight.y, bottomRight.x, bottomRight.y, 0x000000)
                                .setOrigin(0.0, 0.0)
                                .setDepth(OUTLINE_LAYER),
                        this.add.line(drawPair.x, drawPair.y, top.x, top.y, topRight.x, topRight.y, 0x000000)
                                .setOrigin(0.0, 0.0)
                                .setDepth(OUTLINE_LAYER),
                        this.add.line(drawPair.x, drawPair.y, bottom.x, bottom.y, bottomRight.x, bottomRight.y, 0x000000)
                                .setOrigin(0.0, 0.0)
                                .setDepth(OUTLINE_LAYER),
                    ];
                }

                this.tileObjMap.set(`${r}-${c}`, {
                    // tileImg: currTile,
                    tileImgList: currTileList,
                    lines: lineObjects,
                    blockType: currBlockType,
                    height: (currBlockHeight),
                });
            }
        }


        this.selectedTilePolygon = this.add.polygon(-200, -200, [bottom, bottomLeft, topLeft, top, topRight, bottomRight], 0x0101fe)
                    .setOrigin(0.0, 0.0)
                    .setDepth(POLYGON_LAYER);





        this.input.on('pointerup', function(pointer: Phaser.Input.Pointer, currentlyOver: any) {
            canvasStore.dispatch(pointerUpActionCreator({
                x: pointer.x,
                y: pointer.y,
            }));
        });
        this.input.on('pointerdown', function(pointer: Phaser.Input.Pointer, currentlyOver: any) {
            canvasStore.dispatch(pointerDownActionCreator({
                x: pointer.x,
                y: pointer.y,
            }));
        });
        this.input.on('pointermove', function(pointer: Phaser.Input.Pointer, currentlyOver: any) {
            canvasStore.dispatch(pointerMoveActionCreator({
                x: pointer.x,
                y: pointer.y,
            }));
        });



        //https://rexrainbow.github.io/phaser3-rex-notes/docs/site/touchevents/#dragging
        this.input.setTopOnly(true);
        // this.input.setTopOnly(false);
        // ^ VERY NECESSARY FOR OVERLAPPING TILES.
        //here?
    }

    update() {
        let mainStore: GameState = gameStore.getState();

        // this.helloWorld.angle += 1;


        //


        const currDragState: DragState = canvasStore.getState().dragState;
        const offsetX: number = currDragState.offset.x + currDragState.tempOffset.x;
        const offsetY: number = currDragState.offset.y + currDragState.tempOffset.y;


        let drawPair: XYPair;

        let gameLogicState: GameLogicState = canvasStore.getState().gameLogicState;
        let selectedTileObj: TileObj | undefined = this.tileObjMap.get(`${gameLogicState.selectedTile?.r}-${gameLogicState.selectedTile?.c}`);
        if (!!gameLogicState.selectedTile) {
            //drawPair = RCToBottomLeft(gameLogicState.selectedTile.r, gameLogicState.selectedTile.c, offsetX, offsetY);
            //drawPair = RCHToBottomLeft(gameLogicState.selectedTile.r, gameLogicState.selectedTile.c, (selectedTileObj?.height || 0), offsetX, offsetY); // BUGGED
            drawPair = RCHToBottomLeft(gameLogicState.selectedTile.r, gameLogicState.selectedTile.c, (selectedTileObj?.height || 1) - 1, offsetX, offsetY);
            this.selectedTilePolygon
                    ?.setPosition(drawPair.x, drawPair.y)
                    .setOrigin(0.0, 0.0)
                    .setDepth(POLYGON_LAYER);
            // this
        } else {
            this.selectedTilePolygon
                    ?.setPosition(-200, -200)
                    .setOrigin(0.0, 0.0)
                    .setDepth(POLYGON_LAYER);
        }


        // R-C loop
        for (let r = 0; r < (mainStore.levelMapBlob?.rowCount || 1); r++) {
            for (let c = 0; c < (mainStore.levelMapBlob?.columnCount || 1); c++) {

                // let currTileObj: TileObj | undefined = this.tileObjMap.get(`${r}-${c}`);

                //let drawPair: XYPair = RCToBottomLeft(r, c, offsetX, offsetY);
                // let drawPair: XYPair = RCHToBottomLeft(r, c, (currTileObj?.height || 0), offsetX, offsetY);


                // let selectedTileObj: TileObj | undefined = this.tileObjMap.get(`${gameLogicState.selectedTile?.r}-${gameLogicState.selectedTile?.c}`);

                //

                // let currTile = this.add.image(drawX, drawY, 'grassTile');
                // this.tileMap.set(`${r}-${c}`, currTile);
                
                let tileObj: TileObj | undefined = this.tileObjMap.get(`${r}-${c}`); //here
                //let currTile: Phaser.GameObjects.Image = this.tileMap.get(`${r}-${c}`);
                // let currTile: Phaser.GameObjects.Image | undefined = tileObj?.tileImg;
                
                //let currTilesList: Array<Phaser.GameObjects.Image> | undefined = tileObj?.tileImgList;
                //let currTilesList: Array<Phaser.GameObjects.Image> | undefined = tileObj?.tileImgList || [];
                let currTilesList: Array<Phaser.GameObjects.Image> = tileObj?.tileImgList || [];

                // for (let h = 0; h < (tileObj?.height || 1); h++) {
                //     let currTileImg = currTilesList[h]; // TODO: THIS HAS AN OUT OF BOUNDS RISK
                // }
                // for (let tileImg in currTilesList)
                currTilesList.forEach((currTile: Phaser.GameObjects.Image, indexAsHeight: number) => {
                    let drawPair: XYPair = RCHToBottomLeft(r, c, indexAsHeight, offsetX, offsetY);

                    currTile?.setPosition(drawPair.x, drawPair.y);

                    currTile?.setOrigin(0.0, 0.0);
                    currTile?.setDepth(BLOCK_LAYER);
                });

                // currTile.x = drawX;
                // currTile?.setX(drawX);
                // currTile?.setY(drawY);
                //HERE
                // currTile?.setPosition(drawPair.x, drawPair.y);
                // // currTile?.setX(drawPair.x);
                // // currTile?.setY(drawPair.y);
                // currTile?.setOrigin(0.0, 0.0);
                // currTile?.setDepth(BLOCK_LAYER);


                // END LOOP HERE



                let topOfPillarHeight: number = tileObj?.height || 1;
                // let topOfPillarDrawPair = RCHToBottomLeft(r, c, topOfPillarHeight, offsetX, offsetY); // BUGGED
                let topOfPillarDrawPair = RCHToBottomLeft(r, c, topOfPillarHeight - 1, offsetX, offsetY);


                tileObj?.lines?.forEach((line) => {
                    //line.setPosition(topOfPillarDrawPair.x, topOfPillarDrawPair.y).setOrigin(0.0, 0.0).setDepth(OUTLINE_LAYER);
                    //line.setPosition(topOfPillarDrawPair.x, topOfPillarDrawPair.y + TILE_HEIGHT_SPACING).setOrigin(0.0, 0.0).setDepth(OUTLINE_LAYER);
                    line.setPosition(topOfPillarDrawPair.x, topOfPillarDrawPair.y).setOrigin(0.0, 0.0).setDepth(OUTLINE_LAYER);
                });
                // tileObj?.leftLine?.setPosition(drawPair.x, drawPair.y).setOrigin(0.0, 0.0);
                // tileObj?.leftLine?.setPosition(drawPair.y);
                // tileObj?.leftLine.setX(0);
                // tileObj?.leftLine.setY(0);
            }
        }
    }
}

//class SimpleGame {
export class SimpleGame {
    constructor(heightParam: any, widthParam: any) {
        const config: Phaser.Types.Core.GameConfig = {
            height: heightParam,
            width: widthParam,
            type: Phaser.CANVAS,
            // type: Phaser.AUTO,
            parent: 'phaserhook',
            backgroundColor: '#24252A',
            scene: MyDemo
        }

        console.log("CALLED THE FOLLOWING: new Phaser.Game(config)");

        this.game = new Phaser.Game(config);
    }
    game: Phaser.Game;
}

// export const startGame = () => {
//     var game = new SimpleGame();
// };

export function startTheGameFullScreen() {

    var game = new SimpleGame("100%", "100%");

    console.log("MADE NEWWWWW");

    return game;
};

export function startTheGame() {
    //var game = new SimpleGame(800, 600);
    var game = new SimpleGame(800, 1500);

    console.log("MADE NEWWWWW");

    return game;
};

export function startTheGameDynamic(height: number, width: number) {

    //console.log("RE-CREATED DYNAMIC");
    console.log("CALLED THE FOLLOWING: var game = new SimpleGame(height, width);");

    //var game = new SimpleGame(800, 600);
    //var game = new SimpleGame(800, 1500);
    var game = new SimpleGame(height, width);

    //console.log("MADE NEWWWWW");

    return game;
};

// window.onload = () => {
//     var game = new SimpleGame();
// };