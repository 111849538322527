



export enum BlockType {
    NONE = 'NONE',
    WATER = 'WATER',
    GRASS = 'GRASS',
    SAND = 'SAND',
    DIRT = 'DIRT',
    AUTUMN = 'AUTUMN',
}

export type RCPair = {
    r: number;
    c: number;
}

export type Block = {
    rcIndex: RCPair;
    height: number;
    blockType: BlockType;
}

export type MapBlob = {
    rowCount: number;
    columnCount: number;
    blocks: Array<Block>;
}

const makeBlock = (row: number, col: number, theHeight: number, theBlockType: BlockType): Block => {
    return {
        rcIndex: {
            r: row,
            c: col,
        },
        height: theHeight,
        blockType: theBlockType,
    };
};

export const LEVEL_ONE_MAP: MapBlob = {
    rowCount: 3,
    columnCount: 5,
    blocks: [
        // Row 0
        {
            rcIndex: { r: 0, c: 0, },
            height: 1,
            blockType: BlockType.NONE,
        }, {
            rcIndex: { r: 0, c: 1, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 0, c: 2, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 0, c: 3, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 0, c: 4, },
            height: 1,
            blockType: BlockType.GRASS,
        },

        // Row 1
        {
            rcIndex: { r: 1, c: 0, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 1, c: 1, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 1, c: 2, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 1, c: 3, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 1, c: 4, },
            height: 1,
            blockType: BlockType.GRASS,
        },

        // Row 2
        {
            rcIndex: { r: 2, c: 0, },
            height: 1,
            blockType: BlockType.NONE,
        }, {
            rcIndex: { r: 2, c: 1, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 2, c: 2, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 2, c: 3, },
            height: 1,
            blockType: BlockType.GRASS,
        }, {
            rcIndex: { r: 2, c: 4, },
            height: 1,
            blockType: BlockType.GRASS,
        },
    ],
};



export const LEVEL_TWO_MAP: MapBlob = {
    rowCount: 4,
    columnCount: 5,
    blocks: [
        // Row 0
        makeBlock(0,0,1, BlockType.NONE),
        makeBlock(0,1,2, BlockType.GRASS),
        makeBlock(0,2,2, BlockType.GRASS),
        makeBlock(0,3,1, BlockType.AUTUMN),
        makeBlock(0,4,1, BlockType.AUTUMN),

        // Row 1
        makeBlock(1,0,2, BlockType.GRASS),
        makeBlock(1,1,2, BlockType.GRASS),
        makeBlock(1,2,1, BlockType.NONE),
        makeBlock(1,3,1, BlockType.AUTUMN),
        makeBlock(1,4,1, BlockType.AUTUMN),

        // Row 2
        makeBlock(2,0,1, BlockType.NONE),
        makeBlock(2,1,1, BlockType.GRASS),
        makeBlock(2,2,1, BlockType.GRASS),
        makeBlock(2,3,1, BlockType.NONE),
        makeBlock(2,4,1, BlockType.AUTUMN),

        // Row 3
        makeBlock(3,0,1, BlockType.NONE),
        makeBlock(3,1,1, BlockType.DIRT),
        makeBlock(3,2,1, BlockType.DIRT),
        makeBlock(3,3,1, BlockType.DIRT),
        makeBlock(3,4,1, BlockType.NONE),
    ],
};


export const DEFAULT_MAP: MapBlob = {
    rowCount: 3,
    columnCount: 4,
    blocks: [
        // Row 0
        makeBlock(0,0,1, BlockType.GRASS),
        makeBlock(0,1,1, BlockType.GRASS),
        makeBlock(0,2,1, BlockType.GRASS),
        makeBlock(0,3,1, BlockType.GRASS),
        makeBlock(0,4,1, BlockType.GRASS),

        // Row 1
        makeBlock(1,0,1, BlockType.GRASS),
        makeBlock(1,1,1, BlockType.GRASS),
        makeBlock(1,2,1, BlockType.GRASS),
        makeBlock(1,3,1, BlockType.GRASS),
        makeBlock(1,4,1, BlockType.GRASS),

        // Row 2
        makeBlock(2,0,1, BlockType.GRASS),
        makeBlock(2,1,1, BlockType.GRASS),
        makeBlock(2,2,1, BlockType.GRASS),
        makeBlock(2,3,1, BlockType.GRASS),
        makeBlock(2,4,1, BlockType.GRASS),
    ],
};




// export enum BlockType {
//     NONE = 'NONE',
//     WATER = 'WATER',
//     GRASS = 'GRASS',
//     SAND = 'SAND',
//     DIRT = 'DIRT',
//     AUTUMN = 'AUTUMN',
// }



