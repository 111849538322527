// import { Observable } from "redux";
// import { Observable } from "redux";
// import { ActionsObservable } from "redux-observable";
import { GameAction, nullActionCreator, setGameVarOnCanvasMountActionCreator, PossibleGameActions, ScreenDimensions } from './actions';
// import { GameAction, setGameVarOnCanvasMountActionCreator, PossibleGameActions, ScreenDimensions } from './actions';

import { of, Observable } from 'rxjs';
// import * as rxjs from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/switchMap';
// import 'rxjs/add/operator/debounce';
// import 'rxjs/add/operator/of';
// import 'rxjs/add/operator/buffer';
// import 'rxjs/add/operator/debounceTime';
// import { Observable } from "rxjs";
// import { Observable } from "rxjs/Observable";

import { switchMap, debounceTime } from 'rxjs/operators';
// import { Store } from "redux";
import { GameState } from "./state";

import { BOTTOM_MARGIN, GameScreens, RIGHT_MARGIN } from '../ScreenUtils';

import { startTheGameDynamic } from '../mainfile';

// import { switchMap } from 'rxjs/operator/switchMap';

//export function resizeScreenEpic(actions: ActionsObservable<GameAction<any>>): Observable<any> {
// export function resizeScreenEpic(actions: ActionsObservable<GameAction<any>>): Observable<any> {
//     return actions
//         .ofType(PossibleGameActions.SCREEN_RESIZE).
//         .switchMap(_ => {
//             return Observable.of(null);
//         })
// }


import { ActionsObservable, Epic, StateObservable } from 'redux-observable';

export const resizeScreenEpic: Epic = (actions: ActionsObservable<GameAction<any>>, store: StateObservable<GameState>): Observable<any> => {
    return actions
        .ofType(PossibleGameActions.SCREEN_RESIZE)
        .pipe(
            debounceTime(100),
            switchMap((action: GameAction<any>) => {
                let screenResizeAction: GameAction<ScreenDimensions> = action;


                console.log("EPIC HAS BEEN CALLED");


                // if (!!store.value.phaserGameObj) {
                //     store.value.phaserGameObj?.scale.resize(screenResizeAction.payload.width - RIGHT_MARGIN, screenResizeAction.payload.height - BOTTOM_MARGIN);
                // }


                if (store.value.currentScreen === GameScreens.DYNAMIC_SCREEN) {
                    console.log("RESIZE EPIC => RESIZED IN-GAME");

                    if (!!store.value.phaserGameObj) {
                        store.value.phaserGameObj?.destroy(true);
                    }


                    let gameWrapper = startTheGameDynamic(screenResizeAction.payload.height - BOTTOM_MARGIN, screenResizeAction.payload.width - RIGHT_MARGIN);
                    let phaserGameVar: Phaser.Game = gameWrapper.game;
    
                    //return of(nullActionCreator());
                    return of(setGameVarOnCanvasMountActionCreator(phaserGameVar));
                }

                console.log("RESIZE EPIC => RESIZED OUTSIDE OF GAME");
                return of(nullActionCreator());
                // let gameWrapper = startTheGameDynamic(screenResizeAction.payload.height - BOTTOM_MARGIN, screenResizeAction.payload.width - RIGHT_MARGIN);
                // let phaserGameVar: Phaser.Game = gameWrapper.game;

                // //return of(nullActionCreator());
                // return of(setGameVarOnCanvasMountActionCreator(phaserGameVar));
            })
        );
}





//export function resizeScreenEpic(actions: ActionsObservable<GameAction<any>>, store: Store<GameState>): Observable<any> {


// export function resizeScreenEpiccc(actions: ActionsObservable<GameAction<any>>, store: Store<GameState>): Observable<any> {
//     return actions
//         .ofType(PossibleGameActions.SCREEN_RESIZE)
//         .pipe(
//             switchMap((action: GameAction<any>) => {

//                 let screenResizeAction: GameAction<ScreenDimensions> = action;

//                 if (!!store.getState().phaserGameObj) {
//                     store.getState().phaserGameObj?.scale.resize(screenResizeAction.payload.width - 20, screenResizeAction.payload.height - 20);
//                 }

//                 console.log("EPIC HAS BEEN CALLED");

//                 // if (!!state.phaserGameObj) {
//                 //     state.phaserGameObj?.scale.resize(screenResizeAction.payload.width - 20, screenResizeAction.payload.height - 20);
//                 // }
                
//                 return of(null);
//                 // return Observable.of(null);
//             })
//         )
// }









// export function resizeScreenEpic(actions: ActionsObservable<GameAction<any>>): Observable<any> {
//     return actions
//         .ofType(PossibleGameActions.SCREEN_RESIZE).operator.call
// }